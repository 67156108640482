import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getDateReservations, selectDateReservations, selectIsLoadingDateReservations, selectProduct } from '../../common/store/productSlice';

const PopoverContainer = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1000,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    width: 'auto',
    minWidth: '700px',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
}));

const DateInfoPopover = ({ day, position, isVisible }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const product = useSelector(selectProduct);
    const reservations = useSelector(selectDateReservations);
    const isLoading = useSelector(selectIsLoadingDateReservations);

    useEffect(() => {
        if (isVisible && day && product) {
            dispatch(getDateReservations({ date: day, product }));
        }
    }, [isVisible, day, product, dispatch]);

    if (!isVisible) return null;

    return (
        <PopoverContainer
            style={{
                left: position?.x || 0,
                top: position?.y || 0
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ paddingLeft: '16px' }}>
                {t("popups.availability_calendar.title")}
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("popups.availability_calendar.columns.nr")}</TableCell>
                            <TableCell>{t("popups.availability_calendar.columns.status")}</TableCell>
                            <TableCell>{t("popups.availability_calendar.columns.anzahl")}</TableCell>
                            <TableCell>{t("popups.availability_calendar.columns.person")}</TableCell>
                            <TableCell>{t("popups.availability_calendar.columns.von")}</TableCell>
                            <TableCell>{t("popups.availability_calendar.columns.bis")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <CircularProgress size={24} />
                                </TableCell>
                            </TableRow>
                        ) : reservations.length > 0 ? (
                            reservations.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.reservationNr}</TableCell>
                                    <TableCell>{item.contractType}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.customer}</TableCell>
                                    <TableCell>{item.startDate}</TableCell>
                                    <TableCell>{item.endDate}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    {t("popups.availability_calendar.no_reservations")}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </PopoverContainer>
    );
};

export default DateInfoPopover;
