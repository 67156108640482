import { client } from './client';
import { Storage } from '../utils/Storage';
import ShopConfig from "./config/shopConfig";
import DateTimeFormatConfig from '../utils/DateTimeFormatConfig';

function getApiUrl(action) {
    const BASE_URL = ShopConfig.config().BASE_URL + 'frontend_api/v2/?method=' + action;
    return BASE_URL;
}

export class Api {

    static localCall(action, data) {
        return client.post(
            ShopConfig.config().BASE_URL + action,
            data,
            // send as post - only for local calls to auth.php
            {formDataPost: true}
        );
    }

    static call(action, data) {
        return client.post(
            getApiUrl(action),
            data
        );
    }

    static getSpeziForArticles(customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId) {
        return client.post(getApiUrl("getSpeziForArticles"), {customerTenantId, articleTenantId, start_date, start_time, end_date, end_time, filter, specFilter, locationId, categoryId});
    }

    static getProduct(articleId, cartArticlesTenantId, selectedWarehouseId, startMonth, startYear, endMonth, endYear) {
        if(typeof(articleId) === "string") {
            articleId = articleId.trim();
        }

        let data = {
            "article_id": articleId,
            "cart_articles_tenant_id": cartArticlesTenantId,
            "location_id": selectedWarehouseId,
            startMonth,
            startYear,
            endMonth,
            endYear
        };

        return Api.call(
            "get_product",
            data
        );
    }

    static getSimilars(itemId, from, to, cartArticlesTenantId, selectedWarehouseId) {
        if(typeof(itemId) === "string") {
            itemId = itemId.trim();
        }

        from = DateTimeFormatConfig.standardServerDateFormat(from);
        to = DateTimeFormatConfig.standardServerDateFormat(to);

        let data = {
            "article_id": itemId,
            "from": from,
            "to": to,
            "location_id": selectedWarehouseId,
            "cart_articles_tenant_id": cartArticlesTenantId
        };

        return Api.call(
            "get_similar_products",
            data
        );
    }

    static getAccessories(itemId, from, to, cartArticlesTenantId, selectedWarehouseId) {
        if(typeof(itemId) === "string") {
            itemId = itemId.trim();
        }

        from = DateTimeFormatConfig.standardServerDateFormat(from);
        to = DateTimeFormatConfig.standardServerDateFormat(to);

        let data = {
            "article_id": itemId,
            "from": from,
            "to": to,
            "location_id": selectedWarehouseId,
            "cart_articles_tenant_id": cartArticlesTenantId
        };

        return Api.call(
            "get_accessories",
            data
        );
    }
    
    static getProductsCount(catId, from, to, search, filter, speziFilters, selectedWarehouseId, trackSearchTerm = false) {
        from = DateTimeFormatConfig.standardServerDateFormat(from);
        to = DateTimeFormatConfig.standardServerDateFormat(to);
        
        let data = {
            "category_id": catId,
            "from": from,
            "to": to,
            "search": search,
            "filter": filter,
            "location_id": selectedWarehouseId,
            "speziFilters": speziFilters,
            trackSearchTerm
        };

        return Api.call(
            "get_products_count",
            data
        );
    }
    
    static getProducts(catId, from, to, search, filter, speziFilters, selectedWarehouseId, cartArticlesTenantId, page, productsPerPage) {
        from = DateTimeFormatConfig.standardServerDateFormat(from);
        to = DateTimeFormatConfig.standardServerDateFormat(to);

        let data = {
            "category_id": catId,
            "from": from,
            "to": to,
            "search": search,
            "filter": filter,
            "location_id": selectedWarehouseId,
            "cart_articles_tenant_id": cartArticlesTenantId,
            "speziFilters": speziFilters,
            "page": page,
            "productsPerPage": productsPerPage,
        };

        return Api.call(
            "get_products",
            data
        );
    }

    static getShopSettings() {
        let selectedWarehouseId = Storage.getLocationId();

        return Api.call(
            "get_shop_settings",
            {
                location_id: selectedWarehouseId
            }
        );
    }

    static getUserWarehouseLocations() {
        return Api.call(
            "get_user_warehouse_locations",
            {
                location_id: 0
            }
        );
    }

    static saveDontShowHelpPopup() {
        return Api.call(
            "save_dont_show_help_popup",
            {
                show_shop_help_popup: false
            }
        );
    }

    static getCategories(location) {
        return Api.call(
            "get_categories",
            {
                location_id: location
            }
        );
    }

    static login(loginData, iam_data) {
        let data = loginData;

        if(iam_data) {
            data.iam_data = iam_data;
        }

        return Api.call(
            "login",
            data
        );
    }

    static getReturnKey(selectedWarehouseId) {
        let data = {
            "location_id": selectedWarehouseId
        };

        return Api.call(
            "get_return_key",
            data
        );
    }

    static checkAvailabilityForProducts(itemsData, cartArticlesTenantId, selectedWarehouseId) {
        const data = {
            "itemsData": itemsData,
            "location_id": selectedWarehouseId,
            cartArticlesTenantId
        };
           
        return Api.call(
            "check_availability_for_products",
            data
        );
    }

    static getCheckoutOptions(cartArticleIds) {
        let data = {};
        data.articles = cartArticleIds;

        return Api.call(
            "get_checkout_options",
            data
        );
    }

    static getCostCenters(prefix) {
        let data = {prefix};
        
        return Api.call(
            "get_cost_centers",
            data
        );
    }

    static makeReservation(data) {
        // console.log("Make reservation with data: ", data);

        return Api.call(
            "make_reservation",
            data
        );
    }

    static checkUsername (username) {
        const data = { username };
        return Api.call(
            "check_username",
            data
        );
    }

    static getInsitutions () {
        return Api.call(
            "get_institutions",
            {}
        );
    }

    static registerUser (data) {
        return Api.call(
            "register_user",
            data
        );
    }

    static requestPasswordResetCode(emailUsername) {
        let data = {
            emailUsername
        };

        return Api.call(
            "request_password_reset_code",
            data
        );
    }

    static resetPassword(emailUsername, resetCode, newPassword) {
        let data = {
            emailUsername, 
            resetCode, 
            newPassword
        };

        return Api.call(
            "reset_password",
            data
        );
    }

    static getCalendar (month, year, selectedWarehouseId, articleId, subarticleId, include_ended = false) {
        if(typeof(articleId) == "string") {
            articleId = articleId.trim();
        }

        let data = {
            articleId, 
            month, 
            year,
            location_id: selectedWarehouseId,
            include_ended
        };

        if(subarticleId) {
            data.subarticle_id = subarticleId;
        }

        return Api.call(
            "get_calendar",
            data
        );
    }

    static getSubarticleCalendar (subArticleID, month, year, selectedWarehouseId){
        if(typeof(subArticleID) == "string") {
            subArticleID = subArticleID.trim();
        }

        let data = {
            subarticle_id: subArticleID, 
            month, 
            year,
            location_id: selectedWarehouseId
        };

        return Api.call(
            "get_subarticle_calendar",
            data
        );
    }

    static getDateReservations(articleId, date, selectedWarehouseId) {
        if(typeof(articleId) == "string") {
            articleId = articleId.trim();
        }

        let data = {
            article_id: articleId,
            date: date,
            location_id: selectedWarehouseId
        };

        return Api.call(
            "get_date_reservations",
            data
        );
    }

    static get_iam_data(username, password) {

        // localCall sends data as POST
        return this.localCall('api/auth.php?method=get_iam_data',
            {
                username: username,
                password: password
            }
        );
    }

    static showAttachment(uid, type) {
        if(typeof(uid) == "string") {
            uid = uid.trim();
        }
        window.open(ShopConfig.config().getURLForAttachmentFile(uid), '_blank');
    }
}
