import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { Api } from "../api/api";
import { RecurrenceUtils } from '../ui/qvshop/components/recurringIntervalSelector/utils/RecurrenceUtils';
import { ShopDatesInterval } from '../utils/ShopDatesInterval';
import { AvailabilityUtils } from './utils/AvailabilityUtils';
import { CartSliceUtils } from './utils/CartSliceUtils';
import { ProductUtils } from './utils/ProductUtils';

import DateTimeFormatConfig from '../utils/DateTimeFormatConfig';
import dayjs from 'dayjs';

const STATUS_IDLE = 'idle';
const STATUS_LOADING = 'loading';
const STATUS_SUCCEEDED = 'succeeded';
const STATUS_FAILED = 'failed';

const initialState = {
    status: STATUS_IDLE,
    error: null,

    product: null,
    product_availability_status: STATUS_SUCCEEDED,
    product_availability_error: null,
    product_availability_trigger: new Date().getTime(),

    accessories: [],
    accessories_status: STATUS_IDLE,
    accessories_availability_status: STATUS_SUCCEEDED,
    accessories_availability_error: null,
    accessories_availability: null,

    similars: [],
    similars_status: STATUS_IDLE,
    similars_availability_status: STATUS_SUCCEEDED,
    similars_availability_error: null,
    similars_availability: null,
    
    date_reservations: [],
    date_reservations_status: STATUS_IDLE,
    date_reservations_error: null,
};

export function downloadAttachment() {
    return (dispatch, getState) => {
        let attachment = getState().product.product.attachment
        Api.showAttachment(attachment.uid);
    };
}

export const getProduct = createAsyncThunk(
    'shop/getProduct',
    async ({ productId }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        const startDate = state.filters.startDate;
        const endDate = state.filters.endDate;
        const startAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
        const endAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);
        const startMonth = startAsDate.format("MM");
        const startYear = startAsDate.format("YYYY");
        const endMonth = endAsDate.format("MM");
        const endYear = endAsDate.format("YYYY");

        const response = await Api.getProduct(productId, cartArticlesTenantId, location, startMonth, startYear, endMonth, endYear);
        return response;
    }
)

export const getFromAvailabilityCalendar = createAsyncThunk(
    'shop/getFromAvailabilityCalendar',
    async ({ month, year, product, subarticle }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        const subarticleId = subarticle?.id ? subarticle.id : false;

        const response = await Api.getCalendar(month, year, location, articleId, subarticleId);
        return response;
    }
)

export const getToAvailabilityCalendar = createAsyncThunk(
    'shop/getToAvailabilityCalendar',
    async ({ month, year, product, subarticle }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        const subarticleId = subarticle?.id ? subarticle.id : false;

        const response = await Api.getCalendar(month, year, location, articleId, subarticleId);
        return response;
    }
)

// same as getFromAvailabilityCalendar/getToAvailabilityCalendar - it should replace them
export const getAvailabilityCalendarForMonth = createAsyncThunk(
    'shop/getAvailabilityCalendarForMonth',
    async ({ month, year, product, subarticle, field }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        const subarticleId = subarticle?.id ? subarticle.id : false;

        const response = await Api.getCalendar(month, year, location, articleId, subarticleId, true);
        return { response, field };
    }
)

export const getDateReservations = createAsyncThunk(
    'shop/getDateReservations',
    async ({ date, product }, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const articleId = product.product_id;
        
        // Format date to YYYY-MM-DD
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        
        const response = await Api.getDateReservations(articleId, formattedDate, location);
        return response;
    }
)

export const checkAvailabilityForProduct = createAsyncThunk(
    'shop/checkAvailabilityForProduct',
    async (_noParams, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const product = state.product.product;
        const products = {};
        products[product.product_id] = product;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        
        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(products, state);
        
        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);
        
        return response;
    }
)

export const checkAvailabilityForAccessories = createAsyncThunk(
    'shop/checkAvailabilityForAccessories',
    async (_noParams, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const products = state.product.accessories;
        const product = state.product.product;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        
        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(products, state, product, false/* do not read availability for each day */);
        
        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);
        
        return response;
    }
)

export const checkAvailabilityForSimilars = createAsyncThunk(
    'shop/checkAvailabilityForSimilars',
    async (_noParams, { getState }) => {
        const state = getState();
        const location = state.filters.location;
        const products = state.product.similars;
        const product = state.product.product;
        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);
        
        const dataForAvailability = AvailabilityUtils.prepareAvailabilityDataForProducts(products, state, product, false/* do not read availability for each day */);
        
        const response = await Api.checkAvailabilityForProducts(dataForAvailability, cartArticlesTenantId, location);
        
        return response;
    }
)

export const getAccessories = createAsyncThunk(
    'shop/getAccessories',
    async ({ productId }, { getState }) => {
        const state = getState();
        const location = state.filters.location;

        const recurring = state.filters.recurring;
        const startDate = recurring ? state.filters.startDateRecurring : state.filters.startDate;
        const endDate = recurring ? state.filters.endDateRecurring : state.filters.endDate;

        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);

        const response = await Api.getAccessories(productId, startDate, endDate, cartArticlesTenantId, location);
        return response;
    }
)

export const getSimilars = createAsyncThunk(
    'shop/getSimilars',
    async ({ productId }, { getState }) => {
        const state = getState();
        const location = state.filters.location;

        const recurring = state.filters.recurring;
        const startDate = recurring ? state.filters.startDateRecurring : state.filters.startDate;
        const endDate = recurring ? state.filters.endDateRecurring : state.filters.endDate;

        const cartArticlesTenantId = CartSliceUtils.getCartItemsTenantId(state);

        const response = await Api.getSimilars(productId, startDate, endDate, cartArticlesTenantId, location);
        return response;
    }
)

export const slice = createSlice({
    name: 'product',
    initialState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        resetStatusToIdle: (state) => {
            state.status = STATUS_IDLE;
            state.error = null;
        },
        setAvailabilityStatusLoading: (state) => {
            state.product_availability_status = STATUS_LOADING;
        },
        setAvailabilityStatusIdle: (state) => {
            state.product_availability_status = STATUS_IDLE;
        },
        triggerProductAvailability: (state) => {
            state.product_availability_trigger = new Date().getTime();
        },
        replaceProduct: (state, action) => {
            state.product = action.payload;
        },
        replaceSubarticle: (state, action) => {
            state.product.subarticles[action.payload.id] = action.payload;
        },
        removeProduct: (state) => {
            state.product = null;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getProduct.pending, (state) => {
                state.status = STATUS_LOADING;
                // Remove the previous product while loading the new one
                state.product = null;
                state.accessories = [];
                state.similars = [];
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                if(action.payload.success === true) {
                    state.status = STATUS_SUCCEEDED;
                    state.product = action.payload.article;
                    state.product_availability_trigger = new Date().getTime();
                } else {
                    // ANDY - 03.04.2023 - On dev mode the requests are duplicated so the not found is received twice
                    // A good validation would be to apply changes only on STATUS_LOADING everywhere, but that might conflict with
                    // the StrictMode setting so i'll only apply it here
                    if(state.status === STATUS_LOADING) {
                        state.status = STATUS_FAILED;
                        state.error = action.payload.message;
                    }
                }
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getFromAvailabilityCalendar.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getFromAvailabilityCalendar.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.product = ProductUtils.updateAvailabilityCalendarData(action.payload.calendarData, state.product, 'from_availability_calendar');
            })
            .addCase(getFromAvailabilityCalendar.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getToAvailabilityCalendar.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getToAvailabilityCalendar.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                state.product = ProductUtils.updateAvailabilityCalendarData(action.payload.calendarData, state.product, 'to_availability_calendar');
            })
            .addCase(getToAvailabilityCalendar.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getAvailabilityCalendarForMonth.pending, (state) => {
                state.status = STATUS_LOADING;
            })
            .addCase(getAvailabilityCalendarForMonth.fulfilled, (state, action) => {
                state.status = STATUS_SUCCEEDED;
                const field = action.payload.field || 'availability_calendar';

                if (!state.product[field]) {
                    state.product[field] = {};
                }

                state.product = ProductUtils.updateAvailabilityCalendarData(action.payload.response.calendarData, state.product, field);
            })
            .addCase(getAvailabilityCalendarForMonth.rejected, (state, action) => {
                state.status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(checkAvailabilityForProduct.pending, (state) => {
                state.product_availability_status = STATUS_LOADING;
            })
            .addCase(checkAvailabilityForProduct.fulfilled, (state, action) => {
                state.product_availability_status = STATUS_SUCCEEDED;

                state.product = ProductUtils.updateAvailability(action.payload, state.product);
            })
            .addCase(checkAvailabilityForProduct.rejected, (state, action) => {
                state.product_availability_status = STATUS_FAILED;
                state.product_availability_error = action.payload;
            })

            .addCase(checkAvailabilityForAccessories.pending, (state) => {
                state.product_availability_status = STATUS_LOADING;
            })
            .addCase(checkAvailabilityForAccessories.fulfilled, (state, action) => {
                state.product_availability_status = STATUS_SUCCEEDED;

                for (const [id, product] of Object.entries(state.accessories)) {
                    state.accessories[id] = {...state.accessories[id], available: action.payload[product.product_id]['available']}
                }
            })
            .addCase(checkAvailabilityForAccessories.rejected, (state, action) => {
                state.product_availability_status = STATUS_FAILED;
                state.product_availability_error = action.payload;
            })

            .addCase(checkAvailabilityForSimilars.pending, (state) => {
                state.product_availability_status = STATUS_LOADING;
            })
            .addCase(checkAvailabilityForSimilars.fulfilled, (state, action) => {
                state.product_availability_status = STATUS_SUCCEEDED;

                for (const [id, product] of Object.entries(state.similars)) {
                    state.similars[id] = {...state.similars[id], available: action.payload[product.product_id]['available']}
                }
            })
            .addCase(checkAvailabilityForSimilars.rejected, (state, action) => {
                state.product_availability_status = STATUS_FAILED;
                state.product_availability_error = action.payload;
            })

            .addCase(getAccessories.pending, (state) => {
                state.accessories_status = STATUS_LOADING;
            })
            .addCase(getAccessories.fulfilled, (state, action) => {
                state.accessories_status = STATUS_SUCCEEDED;
                state.accessories = action.payload.articles;
            })
            .addCase(getAccessories.rejected, (state, action) => {
                state.accessories_status = STATUS_FAILED;
                state.error = action.payload;
            })

            .addCase(getSimilars.pending, (state) => {
                state.similars_status = STATUS_LOADING;
            })
            .addCase(getSimilars.fulfilled, (state, action) => {
                state.similars_status = STATUS_SUCCEEDED;
                state.similars = action.payload.articles;
            })
            .addCase(getSimilars.rejected, (state, action) => {
                state.similars_status = STATUS_FAILED;
                state.error = action.payload;
            })
            
            .addCase(getDateReservations.pending, (state) => {
                state.date_reservations_status = STATUS_LOADING;
            })
            .addCase(getDateReservations.fulfilled, (state, action) => {
                state.date_reservations_status = STATUS_SUCCEEDED;
                state.date_reservations = action.payload.reservations || [];
            })
            .addCase(getDateReservations.rejected, (state, action) => {
                state.date_reservations_status = STATUS_FAILED;
                state.date_reservations_error = action.payload;
                state.date_reservations = [];
            })
    }
});

export const { setStatus, resetStatusToIdle, setAvailabilityStatusLoading, setAvailabilityStatusIdle, replaceProduct, replaceSubarticle, triggerProductAvailability, removeProduct } = slice.actions;

export function updateProduct(updateInfo) {
    return (dispatch, getState) => {
        dispatch(setAvailabilityStatusLoading());
        const state = getState();
        let item = {...updateInfo.item};
        let subarticle = updateInfo?.subarticle ? {...updateInfo.subarticle} : false;
        const hasExtendedTimes = state.settings.settings.extended_times || false;
        const allArticlesMustBeInTheSameInterval = state.settings.settings.all_articles_in_same_interval;

        let changedEntity = subarticle ? subarticle : item;

        // Apply the changed value
        changedEntity[updateInfo.field] = updateInfo.value;
        if(updateInfo.field === "startDate") {
            const startDate = updateInfo.value;
            const endDate = changedEntity?.endDate ?? state.filters.endDate;
            
            const startDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(startDate);
            const endDateAsDate = DateTimeFormatConfig.getDateFromStandardPickerDateFormat(endDate);
            const startDateIsValid = startDateAsDate.isValid();
            
            if(startDateIsValid) {
                const calculatedDates = ShopDatesInterval.get(state.settings.settings.working_hours, state.settings.settings.semester_settings, 0, 0, startDate, endDateAsDate.isValid() ? endDate : null);
                changedEntity['endDate'] = calculatedDates.endDate;
            }
        }

        changedEntity = ProductUtils.updateOpeningTimesDataForEntity(changedEntity, state, updateInfo.field);

        const itemData = {
            recurring: changedEntity?.recurring ?? state.filters.recurring,
            startDate: changedEntity?.startDate ?? state.filters.startDate,
            endDate: changedEntity?.endDate ?? state.filters.endDate,
            recurrenceType: changedEntity?.recurrenceType ?? state.filters.recurrenceType,
            startDateRecurring: changedEntity?.startDateRecurring ?? state.filters.startDateRecurring,
            endDateRecurring: changedEntity?.endDateRecurring ?? state.filters.endDateRecurring,
            startTime: changedEntity?.startTime ?? state.filters.startTime,
            startTimeError: changedEntity?.startTimeError ?? state.filters.startTimeError,
            endTime: changedEntity?.endTime ?? state.filters.endTime,
            endTimeError: changedEntity?.endTimeError ?? state.filters.endTimeError,
            repetitionInterval: changedEntity?.repetitionInterval ?? state.filters.repetitionInterval,
            recurrenceStartDay: changedEntity?.recurrenceStartDay ?? state.filters.recurrenceStartDay,
            recurrenceEndDay: changedEntity?.recurrenceEndDay ?? state.filters.recurrenceEndDay,
            openingTimesForStartDate: changedEntity?.openingTimesForStartDate ?? state.filters.openingTimesForStartDate,
            openingTimesForEndDate: changedEntity?.openingTimesForEndDate ?? state.filters.openingTimesForEndDate,
        };

        if(allArticlesMustBeInTheSameInterval && item.isConfigurable && item.subarticles) {
            let subarticles = { ...item.subarticles };
            subarticles = RecurrenceUtils.changeSubarticlesData(subarticles, itemData, state, hasExtendedTimes);
            changedEntity.subarticles = subarticles;
        } else {
            changedEntity = {...changedEntity, ...itemData};
            changedEntity = RecurrenceUtils.changeRecurrenceForItem(changedEntity, state, hasExtendedTimes);
        }

        if(subarticle) {
            dispatch(replaceSubarticle(changedEntity));
        } else {
            dispatch(replaceProduct(changedEntity));
        }
        
        if(changedEntity.hasRecurrenceErrors || changedEntity.hasFromToErrors) {
            dispatch(setAvailabilityStatusIdle());
        } else {
            dispatch(triggerProductAvailability());
        }
    };
}

export const selectIsLoading = state => state.product.status === STATUS_LOADING;
export const selectLoadProductFailed = state => state.product.status === STATUS_FAILED;
export const selectIsLoadingAccessories = state => state.product.accessories_status === STATUS_LOADING;
export const selectIsLoadingSimilars = state => state.product.similars_status === STATUS_LOADING;


export const selectProduct = state => state.product.product;
export const selectHasProduct = state => state.product.product !== null;

export const selectIsLoadingProductAvailability = state => state.product.product_availability_status === STATUS_LOADING;
export const selectProductAvailability = state => state.product.product.available;
export const selectTriggerProductAvailability = state => state.product.product_availability_trigger;
export const selectStartDateAvailabilityCalendar = state => state.product?.product?.from_availability_calendar;
export const selectEndDateAvailabilityCalendar = state => state.product?.product?.to_availability_calendar;

export const selectAccessories = state => state.product.accessories;
export const selectSimilars = state => state.product.similars;
export const selectDateReservations = state => state.product.date_reservations;
export const selectIsLoadingDateReservations = state => state.product.date_reservations_status === STATUS_LOADING;

export default slice.reducer;
