import React, { useState, useEffect, useRef } from 'react';
import { Grid, Stack, Box } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { renderWeekPickerDay } from '../../common/ui/qvshop/components/utils/DatePickerUtils';
import { getAvailabilityCalendarForMonth, selectProduct } from '../../common/store/productSlice';
import DateInfoPopover from './DateInfoPopover';

import QVContainer from '../../common/ui/qvshop/components/QVContainer';

import { selectShowReservationsOnHoverInAvailabilityCalendar } from '../../common/store/settingsSlice';

// Custom styled DateCalendar to make selection transparent
const StyledDateCalendar = styled(DateCalendar)(({ theme }) => ({
    '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: 'transparent',
        border: 'none'
    },
    '& .MuiPickersDay-root:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiPickersDay-root.Mui-selected:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiPickersDay-root.Mui-selected:focus': {
        backgroundColor: 'transparent'
    },
    '& .MuiPickersDay-root:focus, & .MuiPickersDay-root.Mui-focused': {
        backgroundColor: 'transparent'
    },
    '& .MuiPickersDay-root.Mui-highlighted': {
        backgroundColor: 'transparent'
    },
    '& .MuiPickersDay-today': {
        border: 'none'
    }
}));

const AvailabilityCalendar = () => {
    const product = useSelector(selectProduct);
    const dispatch = useDispatch();

    const showReservationsOnHoverInAvailabilityCalendar = useSelector(selectShowReservationsOnHoverInAvailabilityCalendar);

    const [currentMonth, setCurrentMonth] = useState(dayjs().startOf('month'));
    const nextMonth = currentMonth.add(1, 'month');

    // Track the month/year values to prevent unnecessary re-fetches
    const [fetchedLeftMonths, setFetchedLeftMonths] = useState({});
    const [fetchedRightMonths, setFetchedRightMonths] = useState({});

    const [hoveredDay, setHoveredDay] = useState(null);
    const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
    const calendarRef = useRef(null);

    const shouldDisableDate = (dayToCheck) => {
        return false;
    };

    const handleDayHoverOut = () => {
        setHoveredDay(null);
    }

    const handleDayHover = (day, event) => {
        if (!showReservationsOnHoverInAvailabilityCalendar) {
            return;
        }

        if (event) {
            // Calculate position for the popover
            const rect = event.currentTarget.getBoundingClientRect();
            const calendarRect = calendarRef.current.getBoundingClientRect();
            
            // Position the popover to the right and slightly below the day cell
            setPopoverPosition({
                x: rect.left - calendarRect.left + 40, // Position to the right of the day cell
                y: rect.top - calendarRect.top + 40     // Slightly above the top of the day cell
            });

            setHoveredDay(day);
        }
    };
    
    useEffect(() => {
        if (product) {
            const currentMonthKey = `${currentMonth.format('MM')}-${currentMonth.format('YYYY')}`;
            
            // Only fetch if we haven't already fetched this month for the left calendar
            if (!fetchedLeftMonths[currentMonthKey]) {
                console.log('Fetching left calendar for:', currentMonthKey);

                dispatch(getAvailabilityCalendarForMonth({
                    month: currentMonth.format('MM'),
                    year: currentMonth.format('YYYY'),
                    product: product,
                    field: 'month_left'
                }));
                
                // Mark this month as fetched for left calendar
                setFetchedLeftMonths(prev => ({
                    ...prev,
                    [currentMonthKey]: true
                }));
            }
        }
    }, [dispatch, product, currentMonth, fetchedLeftMonths]);

    useEffect(() => {
        if (product) {
            const nextMonthKey = `${nextMonth.format('MM')}-${nextMonth.format('YYYY')}`;
            
            // Only fetch if we haven't already fetched this month for the right calendar
            if (!fetchedRightMonths[nextMonthKey]) {
                console.log('Fetching right calendar for:', nextMonthKey);

                dispatch(getAvailabilityCalendarForMonth({
                    month: nextMonth.format('MM'),
                    year: nextMonth.format('YYYY'),
                    product: product,
                    field: 'month_right'
                }));
                
                // Mark this month as fetched for right calendar
                setFetchedRightMonths(prev => ({
                    ...prev,
                    [nextMonthKey]: true
                }));
            }
        }
    }, [dispatch, product, nextMonth, fetchedRightMonths]);

    // hide the navigation buttons
    const HiddenNavigation = () => null;

    if(!product) {
        return null;
    }

    return (
        <QVContainer borderRadius={8}>
            <Stack direction='column' spacing={2}>
                <Box ref={calendarRef} position="relative">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <StyledDateCalendar 
                                    value={currentMonth}
                                    views={['day']}
                                    readOnly
                                    slots={{
                                        leftArrowIcon: HiddenNavigation,
                                        rightArrowIcon: HiddenNavigation,
                                        day: (props) => renderWeekPickerDay(
                                            props, 
                                            true, 
                                            product?.month_left?.[product.product_id] || {}, 
                                            shouldDisableDate,
                                            handleDayHover,
                                            handleDayHoverOut
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <StyledDateCalendar 
                                    value={nextMonth}
                                    views={['day']}
                                    readOnly
                                    onMonthChange={(newMonth) => {
                                        // when the second calendar month changes, update the first calendar
                                        const updatedCurrentMonth = newMonth.subtract(1, 'month');
                                        setCurrentMonth(updatedCurrentMonth);
                                    }}
                                    slots={{
                                        day: (props) => renderWeekPickerDay(
                                            props, 
                                            true, 
                                            product?.month_right?.[product.product_id] || {}, 
                                            shouldDisableDate,
                                            handleDayHover,
                                            handleDayHoverOut
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    
                    <DateInfoPopover 
                        day={hoveredDay} 
                        position={popoverPosition}
                        isVisible={hoveredDay !== null}
                    />
                </Box>
            </Stack>
        </QVContainer>
    );
};

export default AvailabilityCalendar;
