import { Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers';

export const PickersDayForAvailability = styled(PickersDay)(({ theme }) => 
    ({
        backgroundColor: 'transparent',
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        },
        '&.Mui-selected:focus': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        },
        '&.Mui-selected:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        }
    })
);

export const renderWeekPickerDay = (pickersDayProps, withAvailability, availabilityCalendar, shouldDisableDate,
    onMouseOverCallback, onMouseLeaveCallback) => {

    const date = pickersDayProps.day;

    if (!withAvailability || !availabilityCalendar || shouldDisableDate(date)) {
        return <PickersDay {...pickersDayProps} />;
    }

    const formattedDate = date.format("YYYY-MM-DD");
    const availabilityForDay = availabilityCalendar[formattedDate];

    if(!availabilityForDay) {
        return <PickersDay {...pickersDayProps} />;
    }
    
    const percentageForDay = availabilityForDay.percentage;
    const stockLeftForDay = availabilityForDay.stock_left;

    let startWeek = date.startOf('week');
    let endWeek = date.endOf('week');
    let startMonth = date.startOf('month');
    let endMonth = date.endOf('month');
    const prevDayDisabled = shouldDisableDate(date.subtract(1, 'd'));
    const nextDayDisabled = shouldDisableDate(date.add(1, 'd'));

    let isFirstDayOfWeek = date.isSame(startWeek, 'day');
    let isLastDayOfWeek = date.isSame(endWeek, 'day');
    let isFirstDayOfMonth = date.isSame(startMonth, 'day');
    let isLastDayOfMonth = date.isSame(endMonth, 'day');

    let intervalProps = {
        color: '#FDFDFD'
    };
    
    if (isFirstDayOfWeek || isFirstDayOfMonth || prevDayDisabled) {
        intervalProps = {
            ...intervalProps,
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        }
    } 
    
    if (isLastDayOfWeek || isLastDayOfMonth || nextDayDisabled) {
        intervalProps = {
            ...intervalProps,
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        }
    }

    if (percentageForDay > 75) {
        intervalProps.backgroundColor = '#70C1B3';
    } else if (percentageForDay > 50) {
        intervalProps.backgroundColor = '#B3D380';
    } else if (percentageForDay > 25) {
        intervalProps.backgroundColor = '#ECE08C';
    } else if (percentageForDay > 0) {
        intervalProps.backgroundColor = '#FEA180';
    } else if (percentageForDay === -1) {
        intervalProps.backgroundColor = '#70C1B3';
    } else if (percentageForDay === -2) {
        intervalProps.backgroundColor = '#70C1B3';
    } else {
        intervalProps.backgroundColor = '#EB547C';
    }

    return (
        <Box key={"box_"+formattedDate} sx={{ position: 'relative' }} 
            onMouseEnter={(e) => onMouseOverCallback && onMouseOverCallback(date, e)}
            onMouseLeave={() => onMouseLeaveCallback && onMouseLeaveCallback()}>

            {!pickersDayProps.outsideCurrentMonth &&
                <Box display="flex"
                    sx={{
                        position: 'absolute',
                        width: "100%",
                        bottom: "-1px",
                        fontSize: "0.50rem",
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...intervalProps
                    }}
                >
                    { stockLeftForDay }
                </Box>
            }

            <PickersDayForAvailability {...pickersDayProps} />
        </Box>
    );
};
